import bg from "assets/images/bg/bg-sou-coop.jpg";
import section from "assets/images/section/sou-coop.png";
import Eliza from "components/ui/Eliza";
import logo from "assets/images/logo-sou-coop.svg";
import DialogBubble from "components/dialog/dialog-bubble";
import usePointsStore from "store";
import { useEffect, useState } from "react";
import AchievementModal from "components/modal/AchievementModal";
import { useCoop } from "context/CoopContext";

export default function SouCoop() {
	const { setSouCoop, souCoop } = usePointsStore();
	const { coopInfo } = useCoop();

	const [tempoFundacao, setTempoFundacao] = useState(0);

	useEffect(() => {
		if (coopInfo?.constituicao_fundacao) {
			const fundacao = new Date(coopInfo?.constituicao_fundacao);
			const hoje = new Date();
			const diferenca = hoje.getFullYear() - fundacao.getFullYear();
			setTempoFundacao(diferenca);
		}

		if (coopInfo?.situacao === "REGULAR") {
			setSouCoop(true);
		}
	}, [coopInfo, setSouCoop]);

	return (
		<>
			<AchievementModal
				positive={souCoop}
				points={souCoop ? 1500 : 0}
				logo={logo}
				title={
					souCoop
						? `Os dados da ${coopInfo?.nome_fantasia}
						estão atualizados na plataforma SouCoop!`
						: `Os dados da ${coopInfo?.nome_fantasia}
						não estão atualizados na plataforma SouCoop!`
				}
			/>
			{/* <AchievementModal
				positive={false}
				// points={1000}
				logo={logo}
				title={`Os dados da sua coop 
						estão não atualizados na plataforma SouCoop!`}
			/> */}
			<div
				className="bg-black absolute top-0 left-0 w-full h-full"
				style={{
					backgroundImage: `url(${bg})`,
				}}
			>
				<div className="absolute top-32 right-20">
					<img
						src={logo}
						alt="Selo"
						className="w-[400px] mx-auto mt-8 border-8 border-white rounded-full shadow-glow-blue"
					/>
				</div>

				<Eliza
					name="center"
					className="absolute left-[-50px] -bottom-[50px] w-[700px]"
				/>

				<div className="absolute w-[834px] left-[350px] top-[440px] animate-in slide-in-from-left-10 scale-[85%]">
					<DialogBubble model="left" className="h-[280px]">
						<p className="mt-2">
							Você sabia que a média nacional de vida de uma coop no Brasil é de
							20 anos?
						</p>
					</DialogBubble>
				</div>

				<img
					src={section}
					alt="Somos Coop"
					className="w-[780px] animate-in slide-in-from-right-5 absolute right-0 bottom-0 object-contain object-center"
				/>

				<div className="absolute right-0 mx-auto w-[780px] top-[610px] z-20 animate-in slide-in-from-right-5 text-center flex flex-col items-center leading-none ">
					<div
						className="text-3xl font-bold text-pink-500 animate animate-fly"
						style={{
							textShadow: "0px 2px 1px #fff",
						}}
					>
						SUA COOP POSSUI
					</div>
					<span
						className="text-[260px] font-bold leading-[190px] mt-10 text-pink-500 animate animate-fly"
						style={{
							textShadow: "0px 3px 1px #fff",
						}}
					>
						{tempoFundacao}
					</span>
					<div
						className="text-2xl font-bold leading-none text-pink-500 animate animate-fly"
						style={{
							textShadow: "0px 3px 1px #fff",
						}}
					>
						{tempoFundacao === 1 ? "ANO" : "ANOS"} DE FUNDAÇÃO
					</div>
				</div>
			</div>
		</>
	);
}
