import { AxiosError } from "axios";
import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { actionLog } from "services/actionlog.service";
import {
	fetchUser,
	loginRequest,
	registerUserRequest,
} from "services/auth.service";
import { fetchSettings } from "services/jornada.service";
import usePointsStore from "store";
import { User } from "types/user";
import { slugify } from "utils/helpers";
import { clearStorage } from "utils/storage";
import { api } from "../../services/api";
import { setAuthorizationHeader } from "../../services/interceptors";
import {
	createTokenCookies,
	getToken,
	removeTokenCookies,
} from "../../utils/tokenCookies";

export interface SignUpCredentials {
	_id?: string;
	email: string;
	cpf: string;
	cnpj: string;
	name: string;
	newsletter: boolean;
}

export interface LoginCredentials {
	username: string;
	password: string;
}

interface AuthContextData {
	login: (credentials: LoginCredentials) => Promise<void | AxiosError>;
	signUp: (credentials: SignUpCredentials) => Promise<void | AxiosError>;
	signOut: (pathname?: string) => void;
	refreshUser: () => void;
	checkGameIsEnabled: () => Promise<void>;
	isAuthenticated: boolean;
	isGameEnabled: boolean;
	loadingSettings: boolean;
	user?: User;
	registerUser?: SignUpCredentials;
	setRegisterUser?: any;
}

interface AuthProviderProps {
	children: ReactNode;
}

const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: AuthProviderProps) {
	const navigate = useNavigate();
	const [user, setUser] = useState<User>();
	const [registerUser, setRegisterUser] = useState<SignUpCredentials>();
	const token = getToken();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isGameEnabled, setIsGameEnabled] = useState(false);
	const [loadingSettings, setLoadingSettings] = useState(false);
	const { setLotteryEnabled } = usePointsStore();
	async function login(credentials: LoginCredentials) {
		// console.log("login",credentials);
		try {
			const result = await loginRequest(credentials);

			const { access_token, expires_in } = result;

			createTokenCookies(access_token, access_token);
			setAuthorizationHeader(api.defaults, access_token);

			setIsAuthenticated(true);

			// await actionLog("login");

			// Melhorar isso aqui, criar um metodo de sync de user para deixar mais generico
			// Ou remover o setUser automatico deste lugar

			const data = await fetchUser();

			// const { name, image, extra } = data;

			const userData = data;

			setUser(userData);

			return userData;
		} catch (e) {
			throw e;
		}
	}

	async function signUp(credentials: SignUpCredentials) {
		try {
			const username = `${credentials.cpf.replace(/\D/g, "")}`;
			// const username = `${credentials.cnpj}_${slugify(credentials.name, "_")}`;
			// const username = `${credentials.email}`;
			// const result = await registerUserRequest({_id:username, ...credentials, lottery: Math.floor(100000 + Math.random() * 900000)});
			const result = await registerUserRequest({
				_id: username,
				...credentials,
			});
			return result;
		} catch (e) {
			throw e;
		}
	}

	function signOut(pathname = "/login") {
		setUser(undefined);
		setAuthorizationHeader(api.defaults, "");
		removeTokenCookies();
		clearStorage();
		setIsAuthenticated(false);
		// navigate(pathname);
	}

	async function refreshUser() {
		// setLoadingSettings(true);

		const data = await fetchUser();
		const userData = data;
		setUser(userData);

		// Atualizando os dados

		// Marcando como Autenticado

		// setLoadingSettings(false);
	}

	async function checkGameIsEnabled() {
		try {
			setLoadingSettings(true);
			const settings = await fetchSettings();
			const { game_enabled, lottery } = settings;
			setIsGameEnabled(game_enabled);
			setLotteryEnabled(lottery);
			setLoadingSettings(false);
		} catch (e) {
			console.log(e);
		}
	}

	const value = {
		isAuthenticated,
		isGameEnabled,
		checkGameIsEnabled,
		user,
		registerUser,
		setRegisterUser,
		login,
		signUp,
		signOut,
		refreshUser,
		loadingSettings,
	};

	useEffect(() => {
		checkGameIsEnabled();
		if (token) {
			refreshUser();
			setIsAuthenticated(!!token);
		}
	}, []);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within a AuthProvider");
	}
	return context;
}

export { AuthProvider, useAuth };
