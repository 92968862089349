import bg from "assets/images/bg/bg-somos-coop.jpg";
import bgGame from "assets/images/bg/bg-game-capacita.jpg";

import { useCallback, useEffect, useState } from "react";
import usePointsStore from "store";
import { motion } from "framer-motion";

import logo from "assets/images/logo-capacita-coop.svg";
import AchievementModal from "components/modal/AchievementModal";

const QUESTIONS: any[] = [
	{
		question:
			"Em quantos ramos as cooperativas foram divididas para facilitar sua representação?",
		answers: [
			{
				label: "7 ramos",
				correct: true,
			},
			{
				label: "14 ramos",
			},
			{
				label: "10 ramos",
			},
		],
	},
	{
		question:
			"A possibilidade de cooperativas estabelecerem iniciativas conjuntas para o desenvolvimento de atividades afins, ganho de escala ou minimização de riscos obedece ao princípio:",
		answers: [
			{
				label: "Gestão Democrática",
			},
			{
				label: "Intercooperação",
				correct: true,
			},
			{
				label: "Autonomia",
			},
		],
	},
	{
		question:
			"O SESCOOP – Serviço Nacional de Aprendizagem do Cooperativismo – é uma entidade cooperativista que tem a finalidade de contribuir para o cumprimento de um dos princípios cooperativistas. Qual?",
		answers: [
			{
				label: "Adesão livre e voluntária",
			},
			{
				label: "Democracia Interna",
			},
			{
				label: "Educação, Formação e Informação",
				correct: true,
			},
		],
	},
];

export default function CapacitaCoopGame() {
	const {
		points,
		pontosGameCapacitaCoop,
		setPontosGameCapacitaCoop,
		toggleNavigation,
	} = usePointsStore();

	const [currentQuestion, setCurrentQuestion] = useState<any>();
	const [selectedAnswer, setSelectedAnswer] = useState<any>();
	const [gameFinished, setGameFinished] = useState(false);

	const onMessage = useCallback(
		({ data }: any) => {
			if (data.type === "game-finished") {
				toggleNavigation(true);
				if (data.score === 6) {
					// displayPointsNotification(500);
					// setPontosGameSomosCoop(500);
					// setPoints(points + 500);
				}
			}
		},
		[toggleNavigation, points]
	);

	const handlerOnAnswer = useCallback(
		(answer: any) => {
			if (selectedAnswer) return;
			setSelectedAnswer(answer);

			if (answer.correct) {
				setTimeout(() => {
					setPontosGameCapacitaCoop(100);
				}, 500);
			} else {
				setPontosGameCapacitaCoop(0);
			}

			toggleNavigation(true);

			setTimeout(() => {
				setGameFinished(true);
			}, 2000);
		},
		[selectedAnswer, setPontosGameCapacitaCoop]
	);

	const isCorrect = useCallback(
		(answer: any) => {
			if (!selectedAnswer)
				return "bg-white border-b-white text-brand-dark-purple";

			if (answer.label !== selectedAnswer.label) {
				return `bg-white border-b-white text-brand-dark-purple ${
					answer.correct
						? "outline outline-4 outline-green-500 text-green-500 animate animate-wiggle"
						: ""
				}`;
			}

			return selectedAnswer.correct
				? "bg-green-500 border-b-green-400 text-white"
				: "bg-red-500 border-b-red-400 text-white";
		},
		[selectedAnswer]
	);

	const gameOver = useCallback(() => {
		setPontosGameCapacitaCoop(0);
		setGameFinished(true);
		toggleNavigation(true);
	}, [setPontosGameCapacitaCoop]);

	useEffect(() => {
		toggleNavigation(false);
		window.addEventListener("message", onMessage);
		return () => {
			window.removeEventListener("message", onMessage);
			toggleNavigation(true);
		};
	}, [toggleNavigation]);

	useEffect(() => {
		const randomQuestion =
			QUESTIONS[Math.floor(Math.random() * QUESTIONS.length)];
		setCurrentQuestion(randomQuestion);
		setSelectedAnswer(null);
		setGameFinished(false);

		return () => {
			setCurrentQuestion(null);
			setSelectedAnswer(null);
			setGameFinished(false);
		};
	}, [setCurrentQuestion, setSelectedAnswer]);

	return (
		<>
			{pontosGameCapacitaCoop ? (
				<AchievementModal
					positive
					points={pontosGameCapacitaCoop}
					logo={logo}
					title={`Você respondeu corretamente!`}
				/>
			) : null}
			<div
				className="bg-black absolute top-0 left-0 w-full h-full"
				style={{
					backgroundImage: `url(${bg})`,
				}}
			>
				<div className="absolute flex flex-col p-5 gap-5 w-full h-full top-0 left-0 z-10 bg-blue-dark bg-opacity-90">
					<div className="mt-[100px] text-2xl text-white flex flex-col items-center">
						<div className="flex gap-10 items-center">
							<div className="text-center leading-tight">
								Vamos testar seus conhecimentos?
							</div>
						</div>
					</div>
					<div
						className="relative p-4 flex-1 bg-black rounded-xl overflow-hidden bg-center bg-no-repeat"
						style={{
							backgroundImage: `url(${bgGame})`,
							backgroundSize: "auto 100%",
						}}
					>
						{!selectedAnswer ? (
							<motion.div
								className="bg-neon-blue h-4 w-full rounded-full"
								initial={{
									width: "100%",
								}}
								animate={{
									width: 0,
								}}
								transition={{
									duration: 30,
									ease: "linear",
								}}
								onAnimationComplete={() => {
									gameOver();
								}}
							></motion.div>
						) : null}

						{gameFinished ? (
							<div className="flex flex-col gap-5 justify-center absolute top-[100px] left-0 right-0 mx-auto w-[1200px] h-[580px]">
								<div className="px-16 flex flex-col gap-10 justify-center items-center bg-white bg-opacity-85 backdrop-blur border-b-8 border-b-white rounded-xl shadow-glow-blue">
									<div className="text-4xl text-center p-16">
										{pontosGameCapacitaCoop ? (
											<div>
												<h1 className="text-5xl font-bold text-brand-dark-purple">
													Muito bem!
												</h1>
												<p>Vamos continuar nossa jornada?</p>
											</div>
										) : (
											<div>
												<h1 className="text-5xl font-bold text-brand-dark-purple">
													Essa foi por pouco!
												</h1>
												<p>Vamos continuar nossa jornada?</p>
											</div>
										)}
									</div>
								</div>
							</div>
						) : null}

						{!gameFinished && currentQuestion ? (
							<div className="flex flex-col gap-5 justify-center absolute top-[100px] left-0 right-0 mx-auto w-[1200px] h-[580px]">
								<div className="px-16 flex flex-col gap-10 justify-center items-center bg-white bg-opacity-85 backdrop-blur border-b-8 border-b-white rounded-xl shadow-glow-blue">
									<h1 className="text-4xl text-center p-16 font-bold">
										{currentQuestion.question}
									</h1>
								</div>
								{currentQuestion.answers.map((answer: any, i: number) => (
									<div
										key={i}
										onClick={() => handlerOnAnswer(answer)}
										className={`${isCorrect(
											answer
										)} bg-opacity-85 backdrop-blur border-b-8 rounded-xl shadow-glow-blue hover:scale-105`}
									>
										<p className="text-3xl text-center p-5 font-bold">
											{answer.label}
										</p>
									</div>
								))}
							</div>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
}
